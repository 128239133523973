<template>
  <b-card>
    <b-row
      v-for="item in orderData.lines"
      :key="item.id"
      class="match-height mt-2 mb-2"
    >
      <b-col
        cols="auto"
      >
        <b-avatar
          :text="item.avatar_text"
          size="64"
          variant="primary"
          class="mt-2"
        />
      </b-col>
      <b-col>
        <h3 class="mb-0">
          {{ item.package }}
        </h3>
        <div>{{ item.package_content }}</div>
        <small class="text-muted">{{ item.startup }}</small>
        <div class="text-muted mt-1">
          Paket kullanım süresi: {{ item.duration }} {{ $t('Yıl') }}
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BAvatar,
} from 'bootstrap-vue'

export default {
  name: 'Items',
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
  },
  computed: {
    orderData() {
      return this.$store.getters['appOrders/getOrder']
    },
  },
  methods: {
    removeItem(id) {
      this.$store.dispatch('cart/cartRemoveItem', id)
    },
  },
}
</script>

<style scoped>

</style>
