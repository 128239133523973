<template>
  <div>
    <b-card :title="$t('Sipariş Özeti')">
      <hr>
      <div
        v-for="item in orderData.lines"
        :key="item.id"
        class="border-bottom pb-2 pt-2"
      >
        <b-row class="match-height">
          <b-col>
            <h5 class="mb-0 text-primary">
              {{ item.package }}
            </h5>
            <small>1 Yıl</small>
          </b-col>
          <b-col
            cols="auto"
            class="font-weight-bolder font-medium-2"
          >
            {{ item.amount | toCurrency }} ₺
            <small class="text-muted text-right">({{ $t('KDV dahil') }})</small>
          </b-col>
        </b-row>
      </div>
      <div class="mt-2">
        <div class="font-weight-bolder">
          {{ $t('Sipariş Toplamı') }}
        </div>
        <div class="font-medium-4 font-weight-bolder text-primary">
          {{ orderData.total | toCurrency }} ₺
        </div>
      </div>
      <hr>
      <div class="font-weight-bolder">
        {{ $t('Sipariş Durumu') }}
      </div>
      <div
        class="font-medium-3 font-weight-bolder"
        :class="orderData.id_order_statuses === '2'? 'text-success' : 'text-danger'"
      >
        {{ orderData.order_status }}
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'

export default {
  name: 'Totals',
  components: {
    BCard,
    BRow,
    BCol,
  },
  computed: {
    orderData() {
      return this.$store.getters['appOrders/getOrder']
    },
  },
  methods: {
    saveOrder() {
      this.$store.dispatch('appOrders/saveOrderData', this.cart.items)
    },
  },
}
</script>

<style scoped>

</style>
