<template>
  <div>
    <b-alert
      v-if="orderSaveStatus.id"
      variant="success"
      show
    >
      <div class="alert-body text-center">
        <FeatherIcon icon="InfoIcon" />
        {{ orderSaveStatus.message }}
      </div>
    </b-alert>
    <b-card
      v-else
      :title="$t('Ödeme Bildirimi')"
    >
      <b-form-group
        label="Dekont"
        label-for="dekont"
      >
        <b-form-file
          id="dekont"
          v-model="receipt"
          placeholder="Dosya Seçin"
        />
      </b-form-group>
      <b-button
        variant="primary"
        :disabled="!receipt"
        @click="receiptUpload"
      >
        <FeatherIcon icon="UploadIcon" /> Yükle
      </b-button>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BFormFile, BFormGroup, BButton, BAlert,
} from 'bootstrap-vue'

export default {
  name: 'TransferForm',
  components: {
    BCard,
    BFormFile,
    BFormGroup,
    BButton,
    BAlert,
  },
  data() {
    return {
      receipt: null,
    }
  },
  computed: {
    orderSaveStatus() {
      return this.$store.getters['appOrders/getOrderSaveStatus']
    },
  },
  methods: {
    receiptUpload() {
      const data = {
        id: this.$route.params.id,
        receipt: this.receipt,
      }
      this.$store.dispatch('appOrders/receiptUpload', data)
    },
  },
}
</script>

<style scoped>

</style>
