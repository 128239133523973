<template>
  <div>
    <b-row>
      <b-col>
        <items />
        <div v-if="orderData.id_order_statuses === '1' && !orderData.receipt">
          <transfer-form />
        </div>
        <bank-accounts />
      </b-col>
      <b-col
        xs="12"
        md="4"
      >
        <totals />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import Items from '@/views/App/Orders/View/Items.vue'
import Totals from '@/views/App/Orders/View/Totals.vue'
import TransferForm from '@/views/App/Orders/View/TransferForm.vue'
import BankAccounts from '@/views/App/BankAccounts/Index.vue'

export default {
  name: 'OrderView',
  components: {
    BRow,
    BCol,
    Items,
    TransferForm,
    Totals,
    BankAccounts,
  },
  computed: {
    orderData() {
      return this.$store.getters['appOrders/getOrder']
    },
  },
  created() {
    this.getOrder()
  },
  methods: {
    getOrder() {
      this.$store.dispatch('appOrders/orderData', this.$route.params.id)
    },
  },
}
</script>

<style scoped>

</style>
